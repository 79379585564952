<template>
  <v-col cols="12">
    <v-text-field
      v-model="searchValue"
      v-debounced
      hide-details="auto"
      background-color="#EDEDED"
      class="ba-0 rounded-l rounded-r"
      filled
      rounded
      full-width
      placeholder="Поиск"
      clearable
      @click:clear="() => (searchValue = '')"
    />
    <v-simple-table class="mt-2">
      <thead>
        <tr>
          <th>Код</th>
          <th>Описание</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(item, idx) in filtered"
          :key="'type-item-' + idx"
        >
          <td
            width="150px"
            @click="select(item.code)"
          >
            <text-highlight
              :queries="searchValue || ''"
              class="code-txt"
            >
              {{
                item.code
              }}
            </text-highlight>
          </td>
          <td>
            <div class="light-txt mt-1">
              <text-highlight :queries="searchValue || ''">
                {{
                  item.name
                }}
              </text-highlight>
            </div>
          </td>
        </tr>
      </tbody>
    </v-simple-table>
  </v-col>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  props: {
    search: {
      validator: (prop) => typeof prop === "string" || prop === null,
      default: "",
    },
  },
  data: () => ({
    searchValue: "",
  }),
  computed: {
    ...mapGetters({
      categories: "catalogs/getNsiTransportCategories",
    }),
    filtered() {
      if (this.searchValue !== null && this.searchValue.length) {
        return this.categories.filter(
          (i) => i.search.indexOf(this.searchValue.toLowerCase()) !== -1
        );
      }
      return this.categories;
    },
  },
  watch: {
    search: {
      handler(nv) {
        this.searchValue = nv;
      },
      immediate: true,
    },
  },
  created() {
    if (!this.categories.length) {
      this.$store.dispatch("catalogs/fetchNsi");
    }
  },
  methods: {
    select(code) {
      this.$emit("select", code);
    },
  },
};
</script>